<template>
	<div style="margin: 0 auto;text-align: center;">
		<img style="width: 600px;margin-top: 100px;cursor: pointer;" src="../assets/img/404.png" @click="toIndex">
	</div>
</template>

<script>
	export default{
		methods:{
			toIndex(){
				this.$router.push("/")
			}
		}
	}
</script>

<style>
</style>
